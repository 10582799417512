function BrandiaryComponent() {
    return (
        <div className='mx-10'>
        <div className="flex justify-between"> <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/Brandiary.png`}
                    />
            <div className="text-2xl font-bold">iOS Engineer - Brandiary</div>
            </div>
            <div className=" text-sm font-semibold">Seoul, South Korea  | Jan 2020 - Jan 2021(1 year)</div>
            </div>
            <div className="text-gray-200 my-2 font-semibold mb-5">✨ 개인의 가능성을 일깨워주도록 도와주는 퍼스널 브랜딩 플랫폼입니다.  </div>
            <div className='flex flex-row mt-5 h-[30px]'>
            <div className="text-[10px] text md:text-base lg:text-base mr-1 font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden bg-pink-400 text-white rounded-md p-1">UIKit</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-red-300 text-white rounded-md p-1">MVC</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-orange-300 text-white rounded-md p-1">SNS Login</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-violet-300 text-white rounded-md p-1">Radar Chart</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-yellow-300 text-white rounded-md p-1">Firebase</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-blue-300 text-white rounded-md p-1">Cloud Functions</div>
            </div>
            <div className="mt-5 ">
            <div className="my-2 ">• <strong>초기 창업 멤버</strong>로 기획부터 개발, 운영까지 참여하여 성공적으로  <strong>앱스토어에 런칭</strong>했습니다. </div>
            <div className="my-2 ">• <strong>UIkit, Storyboard</strong>를 이용하여 해쉬 태그 기반 피드, 이미지 페이지뷰 등 <strong>커스텀 뷰</strong>를 구현했습니다.</div>
            <div className="my-2 ">• <strong>Firestore와 Storage </strong> 를 이용하여 <strong>게시물을 업로드 및 다운로드</strong> 할 수 있도록 설계하고 개발했습니다.</div>
            </div>

            <div className="flex flex-row justify-between">
            <img
                        className="w-1/5 h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/Brandiary/Brandiary-login.jpeg`}
                    />
                      <img
                        className="w-1/5 h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/Brandiary/Brandiary-tag.jpeg`}
                    />
                      <img
                        className="w-1/5 h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/Brandiary/Brandiary-feed.jpeg`}
                    />
                      <img
                        className="w-1/5 h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/Brandiary/Brandiary-hashfeed.jpeg`}
                    />
                      <img
                        className="w-1/5 h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/Brandiary/Brandiary-graph.jpeg`}
                    />
                    </div>
        </div>
    )
}

export default BrandiaryComponent;
