import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { allCategories, allCategoriesDic } from "../utils/BlogAllCategories"
import { blogAllPosts } from "../utils/BlogAllPosts"

export interface Category {
    id: string
    name: string
    parent: string
    label: string
    entries: string
    entriesInLogin: string
    imgUrl: string
    color: string
}

export interface Post {
    id: string
    categoryId: string
    comments: string
    date: string
    postUrl: string
    title: string
    trackbacks: string
    visibility: string
}

export interface BlogState {
    posts: Post[]
    categories: Category[]
    selectedCategory: string
}

const initialState: BlogState = {
    posts:  blogAllPosts.filter(post => allCategoriesDic[post.categoryId].id == "988866"),
    categories: [],
    selectedCategory: "988866"
}

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        selectCategory: (state, action: PayloadAction<{ categoryId: string,isParent: boolean }>) => {
            state.selectedCategory = action.payload.categoryId
            if (action.payload.isParent) {
                state.posts = allCategories.filter(category => category.parent == action.payload.categoryId || category.id == action.payload.categoryId).map(category => blogAllPosts.filter(post => post.categoryId == category.id)).flatMap(posts => posts)
            } else {
                state.posts = blogAllPosts.filter(post => post.categoryId == action.payload.categoryId)
            }
        },
    }
})

