import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { appBarSlice } from "../redux/AppBarSlice";

function HomeScreen() {
  const actions = appBarSlice.actions;
  const dispatch = useDispatch();

  return (
    <div className="flex-row h-full">
      <div className="flex h-screen items-center justify-center ">
        <div className="flex-row ml-20 mr-auto h-1/2 items-center ">
          <div className=" font-serif text-xl text-white">Hi, I'm Fomagran</div>
          <div className="items-center my-10 font-serif justify-center text-7xl text-white">
            Breathe into the code
          </div>
          <div className="items-center my-5 font-serif justify-center text-white">
            Creative Coder who turns imagination into reality 🧩
          </div>

          {/* <Link
            to="/about"
            className="items-center text-logo font-serif justify-center border-b fon-semibold border-logo w-fit "
          > */}
            {/* <motion.button
              onClick={() => {
                dispatch(actions.setSelectedTab({ selectedTab: "about" }));
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Learn more {">"}
            </motion.button>
          </Link> */}
        </div>
        <img
          className="h-0 lg:w-1/3 lg:h-1/2 lg:mr-36 sm:w-full "
          src={`${process.env.PUBLIC_URL}/assets/khalid.jpg`}
        />
      </div>
      {/* <div className="bg-stone-900 text-white text-5xl font-serif ml-20 -mt-10">
        What I do
      </div>
      <Link
        to="/about"
        className="flex bg-stone-900 ml-20 mb-10 mt-4 text-logo font-serif w-fit border-b fon-semibold border-logo"
      >
        <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          Learn more {">"}
        </motion.button>
      </Link> */}
      {/* <div className="flex w-full h-1/2 items-center">
        <div className="flex w-full mb-10 justify-evenly items-center ">
          <motion.img
            src={`${process.env.PUBLIC_URL}/assets/khalid3.jpg`}
            className="w-1/6 h-1/4 rounded-lg"
            whileTap={{ scale: 1.2, rotateY: 0, transition: { duration: 0.5 } }}
            whileHover={{
              rotateY: 45,
              transition: { ease: "easeOut", duration: 0.3 },
            }}
          />
          <motion.img
            src={`${process.env.PUBLIC_URL}/assets/khalid4.jpg`}
            className="w-1/6 h-1/4 rounded-lg"
            whileTap={{ scale: 1.2, rotateY: 0, transition: { duration: 0.5 } }}
            whileHover={{
              rotateY: 45,
              transition: { ease: "easeOut", duration: 0.3 },
            }}
          />
          <motion.img
            src={`${process.env.PUBLIC_URL}/assets/khalid5.jpg`}
            className=" w-1/6 h-1/4 rounded-lg"
            whileTap={{ scale: 1.2, rotateY: 0, transition: { duration: 0.5 } }}
            whileHover={{
              rotateY: -45,
              transition: { ease: "easeOut", duration: 0.3 },
            }}
          />
          <motion.img
            src={`${process.env.PUBLIC_URL}/assets/khalid6.jpg`}
            className="w-1/6 h-1/4 rounded-lg"
            whileTap={{ scale: 1.2, rotateY: 0, transition: { duration: 0.5 } }}
            whileHover={{
              rotateY: -45,
              transition: { ease: "easeOut", duration: 0.3 },
            }}
          />
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default HomeScreen;
