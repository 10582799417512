import { Category } from "../redux/BlogSlice"

export const allCategories: Category [] = [
    { "id": "865835", "name": "Flutter", "parent": "988835", "label": "📱  Cross Platform/Flutter", "entries": "6", "entriesInLogin": "6", imgUrl: "flutter.jpg",color:"bg-[#53BFF1]" },
    { "id": "988841", "name": "Node.js", "parent": "988837", "label": "⚒ Backend/Node.js", "entries": "11", "entriesInLogin": "11", imgUrl: "nodejs.jpg",color:"bg-[#6FA45F]" },
    { "id": "988842", "name": "Oracle", "parent": "928551", "label": "📚 Database/Oracle", "entries": "12", "entriesInLogin": "12", imgUrl: "oracle.jpg",color:"bg-[#C14431]"  },
    { "id": "988845", "name": "Swift", "parent": "980607", "label": "📌 Language/Swift", "entries": "22", "entriesInLogin": "22", imgUrl: "swift.jpg" ,color:'bg-[#F57B3B]'},
    { "id": "988848", "name": "Algorithm", "parent": "851398", "label": "🖥 Computer Science/Algorithm", "entries": "16", "entriesInLogin": "17", imgUrl: "algorithm.jpg" ,color:'bg-[#9B20EA]'},
    { "id": "988854", "name": "LeetCode", "parent": "916501", "label": "📖 Problem Solution/LeetCode", "entries": "113", "entriesInLogin": "119", imgUrl: "leetcode.png" ,color:'bg-[#E19F1F]'},
    { "id": "988866", "name": "iOS", "parent": "777681", "label": "🍎 iOS/iOS", "entries": "49", "entriesInLogin": "49", imgUrl: "iOS.jpg" ,color:'bg-[#070707'},
    { "id": "777681", "name": "🍎 iOS", "parent": "", "label": "🍎 iOS", "entries": "138", "entriesInLogin": "138", imgUrl: "iOS.jpg",color:'bg-[#F86361]' },
    { "id": "952810", "name": "Angular", "parent": "988836", "label": "💻 Frontend/Angular", "entries": "9", "entriesInLogin": "9", imgUrl: "angular.jpg" ,color:'bg-[#BE122C]'},
    { "id": "980608", "name": "React Native", "parent": "988835", "label": "📱  Cross Platform/React Native", "entries": "25", "entriesInLogin": "25", imgUrl: "react.jpg" ,color:'bg-[#00A4D2]'},
    { "id": "988835", "name": "📱  Cross Platform", "parent": "", "label": "📱  Cross Platform", "entries": "31", "entriesInLogin": "31", imgUrl: "crossplatform.jpg" ,color:'bg-[#2126F8]'},
    { "id": "988846", "name": "Javascript", "parent": "980607", "label": "📌 Language/Javascript", "entries": "9", "entriesInLogin": "9", imgUrl: "javascript.jpg" ,color:'bg-[#EAD54C]'},
    { "id": "988849", "name": "Data Structure", "parent": "851398", "label": "🖥 Computer Science/Data Structure", "entries": "10", "entriesInLogin": "11", imgUrl: "datastructure.jpg" ,color:'bg-[#ADDFBA]'},
    { "id": "988855", "name": "Programmers", "parent": "916501", "label": "📖 Problem Solution/Programmers", "entries": "175", "entriesInLogin": "176", imgUrl: "programmers.jpg" ,color:'bg-[#1E2A3B]'},
    { "id": "988859", "name": "UI", "parent": "777681", "label": "🍎 iOS/UI", "entries": "59", "entriesInLogin": "59", imgUrl: "iOS.jpg" ,color:'bg-[#C43863]'},
    { "id": "988885", "name": "MySQL", "parent": "928551", "label": "📚 Database/MySQL", "entries": "1", "entriesInLogin": "1", imgUrl: "mysql.jpg" ,color:'bg-[#00728B]'},
    { "id": "988836", "name": "💻 Frontend", "parent": "", "label": "💻 Frontend", "entries": "9", "entriesInLogin": "9", imgUrl: "frontend.jpg" ,color:'bg-[#60BC97]'},
    { "id": "988843", "name": "Firebase", "parent": "928551", "label": "📚 Database/Firebase", "entries": "20", "entriesInLogin": "20", imgUrl: "firebase.jpg" ,color:'bg-[#F8C529]'},
    { "id": "988847", "name": "Typescript", "parent": "980607", "label": "📌 Language/Typescript", "entries": "2", "entriesInLogin": "2", imgUrl: "typescript.jpg" ,color:'bg-[#497FC4]'},
    { "id": "988850", "name": "Operating System", "parent": "851398", "label": "🖥 Computer Science/Operating System", "entries": "2", "entriesInLogin": "2", imgUrl: "os.jpg",color:'bg-[#026FB1]' },
    { "id": "988860", "name": "Architecture", "parent": "777681", "label": "🍎 iOS/Architecture", "entries": "12", "entriesInLogin": "12", imgUrl: "iOS.jpg",color:'bg-[#ECE4DB]' },
    { "id": "988837", "name": "⚒ Backend", "parent": "", "label": "⚒ Backend", "entries": "11", "entriesInLogin": "11", imgUrl: "backend.jpg" ,color:"bg-[#022C57]" },
    { "id": "988844", "name": "Tibero", "parent": "928551", "label": "📚 Database/Tibero", "entries": "3", "entriesInLogin": "3", imgUrl: "tibero.jpg" ,color:"bg-[#022C57]" },
    { "id": "988851", "name": "Network", "parent": "851398", "label": "🖥 Computer Science/Network", "entries": "17", "entriesInLogin": "17", imgUrl: "network.jpg" ,color:"bg-[#9B20EA]" },
    { "id": "988857", "name": "Etc", "parent": "916501", "label": "📖 Problem Solution/Etc", "entries": "5", "entriesInLogin": "5", imgUrl: "etc.jpg" ,color:"bg-[#1E2A3B]" },
    { "id": "988861", "name": "Third Party", "parent": "777681", "label": "🍎 iOS/Third Party", "entries": "11", "entriesInLogin": "11", imgUrl: "iOS.jpg" ,color:"bg-[#F86361]" },
    { "id": "988876", "name": "Dart", "parent": "980607", "label": "📌 Language/Dart", "entries": "1", "entriesInLogin": "1", imgUrl: "dart.jpg" ,color:"bg-[#53BFF1]" },
    { "id": "928551", "name": "📚 Database", "parent": "", "label": "📚 Database", "entries": "36", "entriesInLogin": "36", imgUrl: "database.jpg" ,color:"bg-[#026FB1]" },
    { "id": "988852", "name": "Database", "parent": "851398", "label": "🖥 Computer Science/Database", "entries": "3", "entriesInLogin": "3", imgUrl: "database.jpg" ,color:"bg-[#1E2A3B]" },
    { "id": "988864", "name": "WWDC", "parent": "777681", "label": "🍎 iOS/WWDC", "entries": "3", "entriesInLogin": "3", imgUrl: "iOS.jpg" ,color:"bg-[#F86361]" },
    { "id": "988877", "name": "HTML&CSS", "parent": "980607", "label": "📌 Language/HTML&CSS", "entries": "3", "entriesInLogin": "3", imgUrl: "htmlcss.jpg" ,color:"bg-[#BE122C]" },
    { "id": "980607", "name": "📌 Language", "parent": "", "label": "📌 Language", "entries": "37", "entriesInLogin": "37", imgUrl: "language.jpg" ,color:"bg-[#F8C529]" },
    { "id": "988853", "name": "Programming", "parent": "851398", "label": "🖥 Computer Science/Programming", "entries": "12", "entriesInLogin": "12", imgUrl: "programming.png" ,color:"bg-[#ADDFB]A]" },
    { "id": "988865", "name": "SwiftUI", "parent": "777681", "label": "🍎 iOS/SwiftUI", "entries": "4", "entriesInLogin": "4", imgUrl: "iOS.jpg",color:"bg-[#F86361]"  },
    { "id": "851398", "name": "🖥 Computer Science", "parent": "", "label": "🖥 Computer Science", "entries": "61", "entriesInLogin": "63", imgUrl: "programming.png",color:"bg-[#022C57]"  },
    { "id": "916501", "name": "📖 Problem Solution", "parent": "", "label": "📖 Problem Solution", "entries": "317", "entriesInLogin": "324", imgUrl: "algorithm.jpg",color:"bg-[#6FA45F]"  },
    { "id": "859923", "name": "🤼 Git", "parent": "", "label": "🤼 Git", "entries": "6", "entriesInLogin": "6", imgUrl: "git.jpg" ,color:"bg-[#070707]" },
    { "id": "857760", "name": "❗️Error", "parent": "", "label": "❗️Error", "entries": "42", "entriesInLogin": "42", imgUrl: "error.jpg" ,color:"bg-[#F86361]" },
    { "id": "781601", "name": "👟 Etc", "parent": "", "label": "👟 Etc", "entries": "6", "entriesInLogin": "6", imgUrl: "etc.jpg",color:"bg-[#070707]"  },
    { "id": "926802", "name": "👨🏻‍💻 내 이야기", "parent": "", "label": "👨🏻‍💻 내 이야기", "entries": "5", "entriesInLogin": "6", imgUrl: "myself.jpg",color:"bg-[#C43863]"  }
]

export interface CategoryDic {
    [key: string]: Category;
}

export const allCategoriesDic: CategoryDic = {
    "865835": { "id": "865835", "name": "Flutter", "parent": "988835", "label": "📱  Cross Platform/Flutter", "entries": "6", "entriesInLogin": "6", imgUrl: "flutter.jpg",color:"bg-[#53BFF1]" },
    "988841": { "id": "988841", "name": "Node.js", "parent": "988837", "label": "⚒ Backend/Node.js", "entries": "11", "entriesInLogin": "11", imgUrl: "nodejs.jpg",color:"bg-[#6FA45F]" },
    "988842": { "id": "988842", "name": "Oracle", "parent": "928551", "label": "📚 Database/Oracle", "entries": "12", "entriesInLogin": "12", imgUrl: "oracle.jpg",color:"bg-[#C14431]"  },
    "988845": { "id": "988845", "name": "Swift", "parent": "980607", "label": "📌 Language/Swift", "entries": "22", "entriesInLogin": "22", imgUrl: "swift.jpg" ,color:'bg-[#F57B3B]'},
    "988848": { "id": "988848", "name": "Algorithm", "parent": "851398", "label": "🖥 Computer Science/Algorithm", "entries": "16", "entriesInLogin": "17", imgUrl: "algorithm.jpg" ,color:'bg-[#9B20EA]'},
    "988854": { "id": "988854", "name": "LeetCode", "parent": "916501", "label": "📖 Problem Solution/LeetCode", "entries": "113", "entriesInLogin": "119", imgUrl: "leetcode.png" ,color:'bg-[#E19F1F]'},
    "988866": { "id": "988866", "name": "iOS", "parent": "777681", "label": "🍎 iOS/iOS", "entries": "49", "entriesInLogin": "49", imgUrl: "iOS.jpg" ,color:'bg-[#070707'},
    "777681": { "id": "777681", "name": "🍎 iOS", "parent": "", "label": "🍎 iOS", "entries": "138", "entriesInLogin": "138", imgUrl: "iOS.jpg",color:'bg-[#F86361]' },
    "952810": { "id": "952810", "name": "Angular", "parent": "988836", "label": "💻 Frontend/Angular", "entries": "9", "entriesInLogin": "9", imgUrl: "angular.jpg" ,color:'bg-[#BE122C]'},
    "980608": { "id": "980608", "name": "React Native", "parent": "988835", "label": "📱  Cross Platform/React Native", "entries": "25", "entriesInLogin": "25", imgUrl: "react.jpg" ,color:'bg-[#00A4D2]'},
    "988835": { "id": "988835", "name": "📱  Cross Platform", "parent": "", "label": "📱  Cross Platform", "entries": "31", "entriesInLogin": "31", imgUrl: "crossplatform.jpg" ,color:'bg-[#2126F8]'},
    "988846": { "id": "988846", "name": "Javascript", "parent": "980607", "label": "📌 Language/Javascript", "entries": "9", "entriesInLogin": "9", imgUrl: "javascript.jpg" ,color:'bg-[#EAD54C]'},
    "988849": { "id": "988849", "name": "Data Structure", "parent": "851398", "label": "🖥 Computer Science/Data Structure", "entries": "10", "entriesInLogin": "11", imgUrl: "datastructure.jpg" ,color:'bg-[#ADDFBA]'},
    "988855": { "id": "988855", "name": "Programmers", "parent": "916501", "label": "📖 Problem Solution/Programmers", "entries": "175", "entriesInLogin": "176", imgUrl: "programmers.jpg" ,color:'bg-[#1E2A3B]'},
    "988859": { "id": "988859", "name": "UI", "parent": "777681", "label": "🍎 iOS/UI", "entries": "59", "entriesInLogin": "59", imgUrl: "iOS.jpg" ,color:'bg-[#C43863]'},
    "988885": { "id": "988885", "name": "MySQL", "parent": "928551", "label": "📚 Database/MySQL", "entries": "1", "entriesInLogin": "1", imgUrl: "mysql.jpg" ,color:'bg-[#00728B]'},
    "988836": { "id": "988836", "name": "💻 Frontend", "parent": "", "label": "💻 Frontend", "entries": "9", "entriesInLogin": "9", imgUrl: "frontend.jpg" ,color:'bg-[#60BC97]'},
    "988843": { "id": "988843", "name": "Firebase", "parent": "928551", "label": "📚 Database/Firebase", "entries": "20", "entriesInLogin": "20", imgUrl: "firebase.jpg" ,color:'bg-[#F8C529]'},
    "988847": { "id": "988847", "name": "Typescript", "parent": "980607", "label": "📌 Language/Typescript", "entries": "2", "entriesInLogin": "2", imgUrl: "typescript.jpg" ,color:'bg-[#497FC4]'},
    "988850": { "id": "988850", "name": "Operating System", "parent": "851398", "label": "🖥 Computer Science/Operating System", "entries": "2", "entriesInLogin": "2", imgUrl: "os.jpg",color:'bg-[#026FB1]' },
    "988860": { "id": "988860", "name": "Architecture", "parent": "777681", "label": "🍎 iOS/Architecture", "entries": "12", "entriesInLogin": "12", imgUrl: "iOS.jpg",color:'bg-[#ECE4DB]' },
    "988837": { "id": "988837", "name": "⚒ Backend", "parent": "", "label": "⚒ Backend", "entries": "11", "entriesInLogin": "11", imgUrl: "backend.jpg" ,color:"bg-[#022C57]" },
    "988844": { "id": "988844", "name": "Tibero", "parent": "928551", "label": "📚 Database/Tibero", "entries": "3", "entriesInLogin": "3", imgUrl: "tibero.jpg" ,color:"bg-[#022C57]" },
    "988851": { "id": "988851", "name": "Network", "parent": "851398", "label": "🖥 Computer Science/Network", "entries": "17", "entriesInLogin": "17", imgUrl: "network.jpg" ,color:"bg-[#9B20EA]" },
    "988857": { "id": "988857", "name": "Etc", "parent": "916501", "label": "📖 Problem Solution/Etc", "entries": "5", "entriesInLogin": "5", imgUrl: "etc.jpg" ,color:"bg-[#1E2A3B]" },
    "988861": { "id": "988861", "name": "Third Party", "parent": "777681", "label": "🍎 iOS/Third Party", "entries": "11", "entriesInLogin": "11", imgUrl: "iOS.jpg" ,color:"bg-[#F86361]" },
    "988876": { "id": "988876", "name": "Dart", "parent": "980607", "label": "📌 Language/Dart", "entries": "1", "entriesInLogin": "1", imgUrl: "dart.jpg" ,color:"bg-[#53BFF1]" },
    "928551": { "id": "928551", "name": "📚 Database", "parent": "", "label": "📚 Database", "entries": "36", "entriesInLogin": "36", imgUrl: "database.jpg" ,color:"bg-[#026FB1]" },
    "988852": { "id": "988852", "name": "Database", "parent": "851398", "label": "🖥 Computer Science/Database", "entries": "3", "entriesInLogin": "3", imgUrl: "database.jpg" ,color:"bg-[#1E2A3B]" },
    "988864": { "id": "988864", "name": "WWDC", "parent": "777681", "label": "🍎 iOS/WWDC", "entries": "3", "entriesInLogin": "3", imgUrl: "iOS.jpg" ,color:"bg-[#F86361]" },
    "988877": { "id": "988877", "name": "HTML&CSS", "parent": "980607", "label": "📌 Language/HTML&CSS", "entries": "3", "entriesInLogin": "3", imgUrl: "htmlcss.jpg" ,color:"bg-[#BE122C]" },
    "980607": { "id": "980607", "name": "📌 Language", "parent": "", "label": "📌 Language", "entries": "37", "entriesInLogin": "37", imgUrl: "language.jpg" ,color:"bg-[#F8C529]" },
    "988853": { "id": "988853", "name": "Programming", "parent": "851398", "label": "🖥 Computer Science/Programming", "entries": "12", "entriesInLogin": "12", imgUrl: "programming.png" ,color:"bg-[#ADDFB]A]" },
    "988865": { "id": "988865", "name": "SwiftUI", "parent": "777681", "label": "🍎 iOS/SwiftUI", "entries": "4", "entriesInLogin": "4", imgUrl: "iOS.jpg",color:"bg-[#F86361]"  },
    "851398": { "id": "851398", "name": "🖥 Computer Science", "parent": "", "label": "🖥 Computer Science", "entries": "61", "entriesInLogin": "63", imgUrl: "programming.png",color:"bg-[#022C57]"  },
    "916501": { "id": "916501", "name": "📖 Problem Solution", "parent": "", "label": "📖 Problem Solution", "entries": "317", "entriesInLogin": "324", imgUrl: "algorithm.jpg",color:"bg-[#6FA45F]"  },
    "859923": { "id": "859923", "name": "🤼 Git", "parent": "", "label": "🤼 Git", "entries": "6", "entriesInLogin": "6", imgUrl: "git.jpg" ,color:"bg-[#070707]" },
    "857760": { "id": "857760", "name": "❗️Error", "parent": "", "label": "❗️Error", "entries": "42", "entriesInLogin": "42", imgUrl: "error.jpg" ,color:"bg-[#F86361]" },
    "781601": { "id": "781601", "name": "👟 Etc", "parent": "", "label": "👟 Etc", "entries": "6", "entriesInLogin": "6", imgUrl: "etc.jpg",color:"bg-[#070707]"  },
    "926802": { "id": "926802", "name": "👨🏻‍💻 내 이야기", "parent": "", "label": "👨🏻‍💻 내 이야기", "entries": "5", "entriesInLogin": "6", imgUrl: "myself.jpg",color:"bg-[#C43863]"  }
}