
import { portfolioSlice } from "../../../redux/PortfolioSlice";
import { useDispatch } from 'react-redux';
import { brokenGlass, chocoCookie, davidLoading, ds, lp, macMiller, none, sticker, swingSquare, threeDCard, transformer, water, wave, zipper } from "./ProjectInfo";
import { useState } from "react";

export function CreativeCodingComponent() {
 

    const actions = portfolioSlice.actions;
    const dispatch = useDispatch();
    const [seeAll, setSeeAll] = useState(true)

    return (
        <div className='relative mx-10'>
            <div className="flex justify-between">
            <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/Creative-Coding.png`}
                    />
                <div className="text-2xl font-bold">Creative Coding</div>
                </div>
                <div className=" text-sm font-semibold">Nov 2021 - Sep 2022(10 mos)</div>
            </div>

            <div className='flex flex-row mt-5 h-[30px]'>
                <div className="text-[10px] text md:text-base lg:text-base mr-1 font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden bg-red-400 text-white rounded-md p-1">Core Animation</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-orange-300 text-white rounded-md p-1">Core Graphics</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-yellow-500 text-white rounded-md p-1">Core Motion</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-purple-300 text-white rounded-md p-1">AVFoundation</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-amber-300 text-white rounded-md p-1">SpriteKit</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-pink-300 text-white rounded-md p-1">SceneKit</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-emerald-500 text-white rounded-md p-1">Speech</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-indigo-500 text-white rounded-md p-1">EasierPath</div>
            </div>
            <div className='flex flex-row mt-5 h-[30px]'>
                <div className="text-[10px] text md:text-base lg:text-base mr-1 font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden bg-lime-300 text-white rounded-md p-1">CADisplayLink</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-cyan-300 text-white rounded-md p-1">CAEmitterCell</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-rose-300 text-white rounded-md p-1">CATransform3DRotate</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-teal-300 text-white rounded-md p-1">CGGradient</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-blue-300 text-white rounded-md p-1">CGAffineTransform</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-green-300 text-white rounded-md p-1">UIDynamicAnimator</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-logo text-white rounded-md p-1">UICollisionBehavior</div>

            </div>
            <div className="mt-5">
            <div className="my-2 ">✅<strong> Core Animation, Core Motion, Core Graphics </strong> 등의 프레임워크를 사용하여 사용자 인터랙션에 따른 커스텀 애니메이션을 구현했습니다. </div>
                <button className="my-2" onClick={() => window.open('https://github.com/fomagran/Creative-Coding', "_blank")}>https://github.com/fomagran/Creative-Coding</button>
                <div className="my-2 font-bold"> 이미지를 <strong className="text-logo">클릭</strong>하시면 <strong className="text-logo">동영상</strong>으로 구현한 프로젝트를 <strong className="text-logo">소리와 함께 자세히</strong> 확인하실 수 있습니다. </div>
                <div className="grid grid-cols-5 gap-y-10 gap-x-8">
                    <div className="relative items-center flex justify-center">
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: lp }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: lp }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/LP-thumb.jpeg`}
                    />
                    </div>
                    <div className="relative items-center flex justify-center">
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: chocoCookie }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: chocoCookie }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/ChocoCookie-thumb.jpeg`}
                    />
                    </div>
                    <div className="relative items-center flex justify-center">
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: water }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: water }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/Water-thumb.jpeg`}
                    />
                    </div>   
                    <div className="relative items-center flex justify-center">
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: sticker }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: sticker }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/Sticker-thumb.jpeg`}
                    />
                    </div>
                    <div className="relative items-center flex justify-center">
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: zipper }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: zipper }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/Zipper-thumb.jpeg`}
                    />
                     </div>
                    <div className={`${!seeAll ? "hidden" : "relative items-center flex justify-center"}`}>
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: transformer }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: transformer }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/Transformer-thumb.jpeg`}
                    />
                    </div>
                    <div className={`${!seeAll ? "hidden" : "relative items-center flex justify-center"}`}>
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: macMiller }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                     <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: macMiller }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/MacMiller-thumb.jpeg`}
                    />
                    </div>
                    <div className={`${!seeAll ? "hidden" : "relative items-center flex justify-center"}`}>
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: threeDCard }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                     <img
                         onClick={() => dispatch(actions.selectCreativeCoding({ project: threeDCard }))}
                         className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/ThreeDCard-thumb.jpeg`}
                    />
                    </div>                    
                    <div className={`${!seeAll ? "hidden" : "relative items-center flex justify-center"}`}>
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: brokenGlass }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: brokenGlass }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/BrokenGlass-thumb.jpeg`}
                    />
                    </div>
                    <div className={`${!seeAll ? "hidden" : "relative items-center flex justify-center"}`}>
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: swingSquare }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: swingSquare }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/SwingSquare-thumb.jpeg`}
                    />
                    </div>                 
                    <div className={`${!seeAll ? "hidden" : "relative items-center flex justify-center"}`}>
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: ds }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: ds }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/DoctorStrange-thumb.jpeg`}
                    />
                    </div>                   
                    <div className={`${!seeAll ? "hidden" : "relative items-center flex justify-center"}`}>
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: wave }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: wave }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/Wave-thumb.jpeg`}
                    />
                    </div>
                    <div className={`${!seeAll ? "hidden" : "relative items-center flex justify-center"}`}>
                    <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: davidLoading }))}
                        className="hover:cursor-pointer absolute w-1/2 opacity-90"
                        src={`${process.env.PUBLIC_URL}/assets/video-play-icon.png`}
                    />
                     <img
                        onClick={() => dispatch(actions.selectCreativeCoding({ project: davidLoading }))}
                        className="hover:cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/DavidLoading-thumb.jpeg`}
                    />
                    </div>
                </div>
                <div className="flex justify-center w-full text-lg font-bold"> 
                <button className="bg-logo p-2 my-5 rounded-lg" onClick={() => setSeeAll(!seeAll)}> {seeAll ? "대표 프로젝트만 보기" : "프로젝트 모두 보기 "}  </button>
                </div>
            </div>
        </div>
    )
}