export function HardWorking() {
    return (
        <div className="mx-5">
            <div className='text-5xl font-bold  my-5 text-red-300'>Hard-Working 💪</div>
            <div className="mx-5">
                <div className="text-lg text-gray-300 font-semibold"> ⎜<strong className="text-red-300">성실하고 꾸준히</strong> 학습하는 개발자가 되기 위해 노력했습니다. 끊임없이 <strong className="text-blue-300">성장하고</strong> 발전해나갈 자신 있습니다.</div>
                <div className=" text-2xl my-5 font-bold">1 DAY 1 COMMIT</div>
                <div className='flex w-full h-px bg-zinc-500 mb-5'></div>
                <div> ✅ <strong>성실한 개발자</strong>가 되기 위해 <strong>1일 1커밋 운동</strong>을 2020년부터 <strong>745일 동안 하루도 쉬지 않고</strong> 진행한 경험이 있습니다.</div>
                <div className="flex flex-row my-3 w-11/12">
                    <img
                        className="w-1/2 mr-1"
                        src={`${process.env.PUBLIC_URL}/assets/HardWorking/commit.png`}
                    />
                    <img
                        className="w-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/HardWorking/streak.png`}
                    />
                </div>
                <div className=" text-2xl my-5 font-bold">TECH BLOGGER</div>
                <div className='flex w-full h-px bg-zinc-500 mb-5'></div>
                <div> ✅ <strong>배우고 경험한 것</strong>들을 기록하기 위해 2019년부터 테크 블로그를 운영하며 <strong>약 700여개</strong>의 글을 포스팅했습니다. </div>
                <div className="flex flex-row my-3 w-11/12">
                    <img
                        className="w-1/4 "
                        src={`${process.env.PUBLIC_URL}/assets/HardWorking/blog-ios.png`}
                    />
                    <img
                        className="w-1/4 mx-1"
                        src={`${process.env.PUBLIC_URL}/assets/HardWorking/blog-algorithm.png`}
                    />
                    <img
                        className="w-1/4"
                        src={`${process.env.PUBLIC_URL}/assets/HardWorking/blog-cs.png`}
                    />
                    <img
                        className="w-1/4 ml-1"
                        src={`${process.env.PUBLIC_URL}/assets/HardWorking/blog-git.png`}
                    />
                </div>
            </div>
            <div className='mx-5'>
            <div className="mb-3 font-bold"> 대표글 </div>
                <button className="text-emerald-300" onClick={() => window.open('https://fomaios.tistory.com/entry/WWDC-2022-%EC%A0%9C%EB%84%88%EB%A6%AD%EA%B3%BC-%ED%94%84%EB%A1%9C%ED%86%A0%EC%BD%9C%EC%9D%84-%EC%82%AC%EC%9A%A9%ED%95%B4-%EC%BD%94%EB%93%9C-%EC%B6%94%EC%83%81%ED%99%94%ED%95%98%EA%B8%B0-feat-someany-Embrace-Swift-generics', "_blank")}>- 제너릭과 프로토콜을 사용해 코드 추상화하기 (feat. some,any) (Embrace Swift generics)</button> <br/>
                <button className="text-emerald-300" onClick={() => window.open('https://fomaios.tistory.com/entry/WWDC-2022-Swift%EB%A1%9C-%ED%94%84%EB%A1%9C%ED%86%A0%EC%BD%9C-%EC%9D%B8%ED%84%B0%ED%8E%98%EC%9D%B4%EC%8A%A4-%EC%84%A4%EA%B3%84%ED%95%98%EA%B8%B0-Design-protocol-interface-in-Swift', "_blank")}>- Swift로 프로토콜 인터페이스 설계하기 (Design protocol interface in Swift)</button> <br/>
                <button className="text-emerald-300" onClick={() => window.open('https://fomaios.tistory.com/entry/iOSUI-UIBezierPath-%EC%89%BD%EA%B2%8C-%EC%82%AC%EC%9A%A9%ED%95%98%EA%B8%B0-feat-EasierPath', "_blank")}>- UIBezierPath 쉽게 사용하기 (feat. EasierPath) </button> <br/>
                <button className="text-emerald-300" onClick={() => window.open('https://fomaios.tistory.com/entry/iOSUI-Core-Animation%EC%9D%B4%EB%9E%80', "_blank")}>- Core Animation이란? (feat. CALayer)</button> <br/>
                <button className="text-emerald-300" onClick={() => window.open('https://fomaios.tistory.com/entry/Creative-Coding-%EC%BB%AC%EB%9F%AC%ED%92%80%ED%95%9C-%EB%AC%BC%EA%B2%B0-%EC%9B%A8%EC%9D%B4%EB%B8%8C-%EB%A7%8C%EB%93%A4%EA%B8%B0feat-Interactive-Developer?category=936778', "_blank")}>- 컬러풀한 물결 웨이브 만들기(feat. Interactive Developer)</button> <br/>
                </div>
        </div>
    )
}