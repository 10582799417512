export function EasierPathComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
            <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/EasierPath.png`}
                    />
                <div className="text-2xl font-bold">EasierPath</div>
                </div>
                <div className=" text-sm font-semibold">May 2022 - Sep 2022(4 mos)</div>
            </div>
            <div className="mt-5">
            <button className="my-2" onClick={ () => window.open('https://github.com/UISugar/EasierPath', "_blank") }><strong>https://github.com/UISugar/EasierPath</strong> </button>
                <div className="my-2 ">• <strong>🚀 1.0.0 - left, right, up, down</strong>과 같은 메서드를 연속적으로 체이닝하여 경로를 연결해 모양을 구현</div>
                <div className="my-2 ">• <strong>🚀 1.0.1 - start, end, makeLayer</strong> 메서드를 구현하여 경로를 쉽게 Layer에 적용할 수 있도록 구현</div>
                <div className="my-2 ">• <strong>🚀 1.1.0 - Shape Enum</strong>을 추가하여 쉽게 도형을 그릴 수 있도록 구현</div>
                <div className="my-2 ">• <strong>🚀 1.1.1 - makeGradientLayer</strong> 메서드를 추가하여 쉽게 그레디언트를 적용할 수 있도록 구현</div>
                <div className="flex flex-row justify-between">
                    <img
                        className="w-1/5 h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/EasierPath/EasierPath1.png`}
                    />
                    <img
                        className="w-1/5 h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/EasierPath/EasierPath2.png`}
                    />
                    <img
                        className="w-1/5  h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/EasierPath/EasierPath3.png`}
                    />
                      <img
                        className="w-1/5  h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/EasierPath/EasierPath4.png`}
                    />
                      <img
                        className="w-1/5  h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/EasierPath/EasierPath5.png`}
                    />
                </div>
            </div>
        </div>
    )
}