export function ComGenAmericaComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
                <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/ComGen.png`}
                    />
                    <div className="text-2xl font-bold">Software Engineer - ComGen America</div>
                </div>
                <div className=" text-sm font-semibold">Opelika, AL, USA  | Jan 2022 - Jan 2023(1 year)</div>
            </div>
            <div className="text-gray-200 my-2 font-semibold mb-5">✨ 유통/물류를 포함한 서비스 및 서비스 제조 산업 프로그램을 설계하는 글로벌 IT 기업입니다.  </div>
            <div className='flex flex-row mt-5 h-[30px]'>
                <div className="text-[10px] text md:text-base lg:text-base mr-1 font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden bg-indigo-400 text-white rounded-md p-1">React Native</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-blue-300 text-white rounded-md p-1">Typescript</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-red-300 text-white rounded-md p-1">Redux</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-amber-300 text-white rounded-md p-1">RTK-Query</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-purple-300 text-white rounded-md p-1">Prisma</div>
                <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-stone-500 text-white rounded-md p-1">Git-flow</div>
            </div>
            <div className="mt-5">
                <div className="my-2 "> • <strong>Hyundai Alabama</strong> 커뮤니케이션 앱에서 <strong>공지 포스팅, 이미지 및 PDF 뷰, 스케쥴 관리 </strong>등의 기능을 개발했습니다.</div>
                <div className="my-2 "><strong> • Redux-Toolkit</strong> 및 <strong>RTK-Query</strong>를 적용하여 상태 관리와 API 통신 관련 코드를 한 곳에서 관리할 수 있도록 개선하였습니다.  </div>
                <div className="my-2 ">• <strong>Object-Relational-Mapping(Prisma)</strong>을 도입하여 하드 코딩된 SQL문을 객체 지향적인 코드로 개선하여 유지보수 와 여러 개발자들과 협업하기 용이한 환경을 구축하였습니다.         </div>
                <div className="my-2 ">• <strong>Git-flow, Github Issue, Convention</strong> 등을 도입하여 요구사항을 명확하게 정의하고 여러 개발자들과 협업하고 버전 컨트롤을 안정성 있게 관리하도록 리드했습니다.        </div>
            </div>
        </div>
    )
}