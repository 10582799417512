import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface AppBarState {
    selectedTab: string
}

const initialState: AppBarState = {
    selectedTab: 'home'
}

export const appBarSlice = createSlice({
    name: 'appBar',
    initialState,
    reducers: {
        setSelectedTab: (state, action: PayloadAction<{ selectedTab: string }>) => {
            state.selectedTab = action.payload.selectedTab;
        },
    }
})

