import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { appBarSlice } from "../redux/AppBarSlice";
import { RootState } from "../redux/store";
import Menu from "./componenets/Menu";

function AppBar() {
  const selectedTab = useSelector<RootState, string>(
    (state) => state.appBar.selectedTab
  );
  const actions = appBarSlice.actions;
  const dispatch = useDispatch();

  return (
    <div className="flex bg-stone-900">
      <div className="flex ml-10 h-16 items-center font-serif ">
        <Menu
          onClick={() =>
            dispatch(actions.setSelectedTab({ selectedTab: "Home" }))
          }
          tabName={"Home"}
          selectedTab={selectedTab}
        />
        {/* <Menu
          onClick={() =>
            dispatch(actions.setSelectedTab({ selectedTab: "about" }))
          }
          tabName={"about"}
          selectedTab={selectedTab}
        /> */}
          <Menu
          onClick={() =>
            dispatch(actions.setSelectedTab({ selectedTab: "Portfolio" }))
          }
          tabName={"Portfolio"}
          selectedTab={selectedTab}
        />
        <Menu
          onClick={() =>
            dispatch(actions.setSelectedTab({ selectedTab: "Blog" }))
          }
          tabName={"Blog"}
          selectedTab={selectedTab}
        />
      </div>
    </div>
  );
}

export default AppBar;
