
function IntroductionComponent() {
    return (
        <div>
            <div className="flex justify-center">
                <img
                    className="w-1/5 rounded-lg my-10"
                    src={`${process.env.PUBLIC_URL}/assets/young.jpeg`}
                />
            </div>
            <div className="flex-wrap overflow-wrap whitespace-normal h-full text flex flex-row mx-5">
                <div className="mx-5 my-5">
                    <div> 안녕하세요. <strong className="text-red-300">iOS 엔지니어</strong> 안영훈입니다.</div> <br />
                    <div> <strong className="text-blue-300">기업의 이익과 사용자 경험</strong>을 최우선으로 여기며, <strong className="text-green-300">겸손한 자세로 배우고 도전하며 끊임없이 성장</strong>해왔습니다. </div> <br />
                    <div> • 미국과 한국에서 3번의 스타트업 초기 멤버로서 기획부터 운영까지 서비스의 전체 프로세스를 참여하고 이끌었습니다. </div>
                    <div> • Core Animation, Core Graphic을 사용하여 여러 커스텀 UI 인터랙션을 구현했습니다.</div>
                    <div> • CEO, 디자이너, 여러 플랫폼 개발자와 협업하고 MVVM, Redux, ReactorKit, TCA 등의 여러 아키텍쳐를 설계하고 구현했습니다.</div>
                    <div> • Clean-Architecture , SOLID 원칙, OOP, POP 등을 준수하며 유지보수와 확장성이 용이한 소프트웨어를 만들기 위해 노력했습니다.</div>
                    <div> • 2019년부터 약 700여 개의 글을 포스팅하여 총 방문자 80만인 블로그를 운영하고 있습니다.</div>
                </div>
            </div>
        </div>

    )
}

export default IntroductionComponent;