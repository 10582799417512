function CarrotEZComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
            <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/CarrotEZ.png`}
                    />
                <div className="text-2xl font-bold">iOS Engineer - CarrotEZ</div>
                </div>
                <div className=" text-sm font-semibold">Seattle, WA, USA  | Jan 2023 - Sep 2023(9 mos)</div>
            </div>
            <div className="text-gray-200 my-2 font-semibold mb-5">✨ 미국 편의점 통합을 목표로 하는 E-Commerce 스타트업입니다.</div>
            <div className='flex flex-row mt-5 text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap'>
            <div className="mr-1 font-semibold bg-orange-300 text-white rounded-md p-1">SwiftUI</div>
            <div className="mr-1 font-semibold bg-red-300 text-white rounded-md p-1">Clean Architecture</div>
            <div className="mr-1 font-semibold bg-green-300 text-white rounded-md p-1">TCA</div>
            <div className="mr-1 font-semibold bg-purple-300 text-white rounded-md p-1">Swift Concurrency</div>
            <div className="mr-1 font-semibold bg-blue-300 text-white rounded-md p-1">Fastlane</div>
            <div className="mr-1 font-semibold bg-stone-500 text-white rounded-md p-1">Github Actions</div>
            </div>
            <div className="mt-5 ">
                <div className="my-2 ">• <strong>Clean Architecture 와 단방향 아키텍쳐(TCA)</strong> 구조로 각 역할에 맞게 분리하여 유지보수 용이하고 <strong>독립적으로 테스트 가능한</strong> 아키텍쳐를 설계했습니다.  </div>
                <div className="my-2 ">• <strong>Swift Concurrency</strong>를 도입하여 <strong>코드 가독성 및 에러 핸들링 안정성을</strong> 개선했습니다. </div>
                <div className="my-2 ">• <strong>SwiftUI</strong>의 리스트 렌더링 로직을 개선하여 CPU 사용량을 약 <strong>80%</strong>, 소요 시간을 3초에서 0.5초로 <strong>60%</strong> 단축하여 사용자 경험을 향상시켰습니다. </div>
                <div className="my-2 ">• <strong>Fastlane,Github Actions</strong> 앱 배포를 브랜치 푸쉬 한 번으로 자동화하여 수동으로 진행하는 작업 시간보다 약 <strong>90%</strong> 단축하였습니다. </div>
                <div className="my-2 ">• <strong>BDD/DDD</strong> 개발 방식으로 진행하며 각 도메인의 요구사항을 모두 충족하는 테스트를 작성하여 커버리지를 <strong>70%</strong>이상 달성했습니다. </div>
            </div>

            <div className="flex flex-row justify-around">
                <img
                    className="w-1/5 h-1/2 mx-2"
                    src={`${process.env.PUBLIC_URL}/assets/CarrotEZ/CarrotEZ-home.gif`}
                />
                <img
                    className="w-1/5 h-1/2"
                    src={`${process.env.PUBLIC_URL}/assets/CarrotEZ/CarrotEZ-location.gif`}
                />
                <img
                    className="w-1/5 h-1/2 mx-2"
                    src={`${process.env.PUBLIC_URL}/assets/CarrotEZ/CarrotEZ-store.gif`}
                />
                <img
                    className="w-1/5 h-1/2"
                    src={`${process.env.PUBLIC_URL}/assets/CarrotEZ/CarrotEZ-scan.gif`}
                />
                <img
                    className="w-1/5 h-1/2"
                    src={`${process.env.PUBLIC_URL}/assets/CarrotEZ/CarrotEZ-account.png`}
                />
            </div>
        </div>
    )
}

export default CarrotEZComponent;