import { motion } from "framer-motion";
import { Link } from "react-router-dom";

interface MenuProps {
  onClick: () => void;
  tabName: string;
  selectedTab: string;
}

export default function Menu({ onClick, tabName, selectedTab }: MenuProps) {
  return (
    <div>
      <Link to={"/" + tabName} onClick={onClick}>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className={`flex ml-5 items-center text-lg font-semibold mt-5 mr-5 ${
            (selectedTab == tabName || tabName == '') ? "text-logo" : "text-white"
          }`}
        >
          {tabName}
        </motion.button>
      </Link>
    </div>
  );
}
