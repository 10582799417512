export function TimebankComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
            <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/Timebank/Timebank-logo.png`}
                    />
                <div className="text-2xl font-bold">Time Bank</div>
                </div>
                <div className=" text-sm font-semibold">Sep 2020 - Dec 2020(3 mos)</div>
            </div>
            <div className="mt-5">
            <button className="my-2" onClick={ () => window.open('https://apps.apple.com/kr/app/TimeBank/1545539694', "_blank") }><strong>https://apps.apple.com/kr/app/TimeBank/1545539694</strong> </button>
                <div className="my-2 ">• 타임뱅크는 사용한 시간을 돈처럼 관리하고 기록하는 앱입니다. </div>
                <div className="my-2 ">• 주된 기능으로는 <strong>백그라운드 타이머 기능, 차트 분석 </strong> 등이 있습니다.</div>
                <div className="flex flex-row justify-between">
                    <img
                        className="w-1/5 h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/Timebank/Timbank-login.jpeg`}
                    />
                    <img
                        className="w-1/5 h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/Timebank/Timebank-home.jpeg`}
                    />
                    <img
                        className="w-1/5  h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/Timebank/Timebank-timer.jpeg`}
                    />
                    <img
                        className="w-1/5 h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/Timebank/Timebank-analytics.jpeg`}
                    />
                    <img
                        className="w-1/5  h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/Timebank/Timebank-mypage.jpeg`}
                    />
                </div>
            </div>
        </div>
    )
}