import { Category } from '../redux/BlogSlice';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { Post } from '../redux/BlogSlice';


const tistoryPostUrl = (pageNum: number): string => {
    return `https://www.tistory.com/apis/post/list?access_token=${process.env.REACT_APP_TISTORY_ACCESS_TOKEN}&output=json&blogName=fomaios&page=${pageNum}`
}

const tistoryCategoryUrl = (): string => {
    return `https://www.tistory.com/apis/category/list?access_token=${process.env.REACT_APP_TISTORY_ACCESS_TOKEN}&output=json&blogName=fomaios`
}

const allAxioses = Array.from(Array(70), (_, i) => i + 1).map(page => (axios.get(tistoryPostUrl(page))))

export const getAllPosts = async (): Promise<Post[]> => {
    return await axios.all(allAxioses).then(
        axios.spread((...alldata) => {
            const allDatas = alldata.map(posts => posts.data.tistory.item.posts)            
            let allPosts: Post[] = []

            for (let i = 0; i < allDatas.length; i++) {
                for (let j = 0; j < allDatas[i].length; j++) {
                    allPosts.push(allDatas[i][j])
                }
            }
    
            return allPosts
        })
    )
}

export const getPostsByPage = async (page: number): Promise<Post[]> => {
    return await axios.all([axios.get(tistoryPostUrl(page))]).then(
        axios.spread((...alldata) => {
            const allDatas = alldata.map(posts => posts.data.tistory.item.posts)
            let allPosts: Post[] = []

            for (let i = 0; i < allDatas.length; i++) {
                for (let j = 0; j < allDatas[i].length; j++) {
                    allPosts.push(allDatas[i][j])
                }
            }

            return allPosts
        })
    )
}


export const getCategories = async (): Promise<Category[]> => {
    return axios.get(tistoryCategoryUrl())
}



export const blogAPISlice = createApi({
    reducerPath: 'blogAPI',
    baseQuery: fetchBaseQuery({ baseUrl: tistoryPostUrl(1) }),
    endpoints: builder => ({
        getAllPosts: builder.query<String, String>({
            query: () => '/',
        }),
    }),
});

export const {
    useGetAllPostsQuery
} = blogAPISlice;

export default blogAPISlice;