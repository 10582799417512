import { PortfolioState } from '../../../redux/PortfolioSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { portfolioSlice } from "../../../redux/PortfolioSlice";
import { none } from './ProjectInfo';

export function DetailCard() {

    const actions = portfolioSlice.actions;
    const dispatch = useDispatch();
    const portfolioState = useSelector<RootState, PortfolioState>(
        (state) => state.portfolio
    );

    const detailLines = portfolioState.selectedCreativeCoding.detail.split('@');

    return (
        <div className="bg-white text-black w-[700px] h-[750px] overflow-y-scroll rounded-lg">
            <div className='flex flex-row justify-between items-center m-4'>
            <div className="text-4xl text-center ">{portfolioState.selectedCreativeCoding.title}</div>
            <button className='w-[25px] h-[25px] bg-black text-white rounded-xl text-center justify-center hover:cursor-pointer' onClick={ () => dispatch(actions.selectCreativeCoding({ project: none }))}>X</button>
            </div>
            <div className='m-5'>{portfolioState.selectedCreativeCoding.content}</div>
            <div className='flex justify-center items-center'>
            <video className='w-[300px] h-[500px]' src={`${process.env.PUBLIC_URL}${portfolioState.selectedCreativeCoding.videoURL}`} typeof='video/mp4' controls></video>
            </div>
            <div className="">
                {detailLines.map((line, index) => (
                    <div key={index} className='m-5'>
                        {line}
                    </div>
                ))}
            </div>
        </div>
    );
}