import { motion } from "framer-motion";

function AboutScreen() {
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;

  return (
    <div className="flex h-full w-full flex-wrap justify-center items-center bg-stone-900">
      <motion.div
        className=" text-5xl font-serif ml-24 font-bold text-white"
        initial={{ x: 100 }}
        animate={{
          scale: [0, 2, 1],
          y: [0, 0, -screenHeight / 2 + 24],
        }}
        transition={{ ease: "easeOut", duration: 5 }}
      >
        About Me
      </motion.div>
      <motion.img
        className="w-1/5 h-3/5 -ml-28"
        src={`${process.env.PUBLIC_URL}/assets/khalid2.jpg`}
        initial={{ opacity: 0, x: -screenWidth / 10 }}
        animate={{ opacity: 1, x: -screenWidth / 6 }}
        transition={{ delay: 5, ease: "easeOut", duration: 3 }}
      />
      <div className="w-1/5 h-1/2 ml-80 mt-20 absolute justify-center items-center text-center">
        <motion.div
          className="font-serif font-bold text-white"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 100 }}
          transition={{ delay: 8, ease: "easeOut", duration: 3 }}
        >
          Maecenas quis elementum nulla, in lacinia nisl. Ut rutrum fringilla
          aliquet. Pellentesque auctor vehicula malesuada. Aliquam id feugiat
          sem, sit amet tempor nulla. Quisque fermentum felis faucibus, vehicula
          metus ac, interdum nibh. Curabitur vitae convallis ligula. Integer ac
          enim vel felis pharetra laoreet. Interdum et malesuada fames ac ante
          ipsum primis in faucibus. Pellentesque hendrerit ac augue quis
          pretium. Morbi ut scelerisque nibh. Integer auctor, massa non dictum
          tristique, elit metus efficitur elit, ac pretium sapien nisl nec ante.
          In et ex ultricies, mollis mi in, euismod dolor. Quisque convallis
          ligula non magna efficitur tincidunt.
        </motion.div>
      </div>
      <div />
    </div>
  );
}

export default AboutScreen;
