function LikeyComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
            <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/Likey.png`}
                    />
                <div className="text-2xl font-bold">iOS Engineer - Likey</div>
                </div>
                <div className=" text-sm font-semibold">Seoul, South Korea  | Jan 2024 - Current</div>
            </div>
            <div className="text-gray-200 my-2 font-semibold mb-5">✨ Like는 크리에이터와 팬을 연결시켜 주는 글로법 소셜 플랫폼입니다..</div>
            <div className='flex flex-row mt-5 text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap'>
            <div className="mr-1 font-semibold bg-orange-300 text-white rounded-md p-1">SwiftUI</div>
            <div className="mr-1 font-semibold bg-red-300 text-white rounded-md p-1">Combine</div>
            <div className="mr-1 font-semibold bg-green-300 text-white rounded-md p-1">UIKit</div>
            <div className="mr-1 font-semibold bg-purple-300 text-white rounded-md p-1">Core Data</div>
            <div className="mr-1 font-semibold bg-blue-300 text-white rounded-md p-1">Agora</div>
            </div>
            <div className="mt-5 ">
                <div className="my-2 ">• 영상 통화 및 라이브 <strong>페이스 필터</strong> 기능 추가  </div>
                <div className="my-2 ">• 라이브 피쳐(SwiftUI & Combine 기반) <strong>CPU 성능 및 스크롤 렌더링</strong> 개선</div>
                <div className="my-2 ">• 라이브 및 영상 통화 성능 개선 및  <strong>State 기반 설계 리팩터링</strong> </div>
                <div className="my-2 ">• 영상 통화  <strong>Storyboard → Code 베이스 및 Rx → Combine 전환</strong> </div>
                <div className="my-2 ">• 이 외 전체적인 기능(피드, 채팅, 알림 등)의 자잘한 수정 및 에러 수정  </div>
            </div>

            <div className="flex flex-row justify-around">
                <img
                    className="w-1/5 h-1/2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-feed.png`}
                />
                <img
                    className="w-1/5 h-1/2 mx-2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-video-chat-image.jpg`}
                />
                <img
                    className="w-1/5 h-1/2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-video-chat.gif`}
                />
                <img
                    className="w-1/5 h-1/2 mx-2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-live-creator.gif`}
                />
                <img
                    className="w-1/5 h-1/2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-live-fan.gif`}
                    />
            </div>
        </div>
    )
}

export default LikeyComponent;