export function FomaViewableSoundComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
                <div className="text-2xl font-bold">FomaViewableSound</div>
                <div className=" text-sm font-semibold">May 2021 - Jun 2021(1 mos)</div>
            </div>
            <div className="mt-5">
            <button className="my-2" onClick={ () => window.open('https://github.com/UISugar/FomaViewableSound', "_blank") }><strong>https://github.com/UISugar/FomaViewableSound</strong> </button>
                <div className="my-2 ">• <strong>🚀 1.0.0 - </strong>음악 소리에 따라 다른 건물이 나오도록 구현</div>
                <div className="flex flex-row justify-between">
                <img
                        className="w-1/4 h-[300px] "
                        src={`${process.env.PUBLIC_URL}/assets/CreativeCoding/CreativeCoding-FomaViewableSound.gif`}
                    />
                    <img
                        className="w-1/4 h-[300px] mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/FomaViewableSound/FomaViewableSound1.png`}
                    />
                    <img
                        className="w-1/4 h-[300px] mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/FomaViewableSound/FomaViewableSound2.png`}
                    />
                    <img
                        className="w-1/4 h-[300px]"
                        src={`${process.env.PUBLIC_URL}/assets/FomaViewableSound/FomaViewableSound3.png`}
                    />
                </div>
            </div>
        </div>
    )
}