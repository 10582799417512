export function MagicFightComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
            <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/Magic-Fight.webp`}
                    />
                <div className="text-2xl font-bold">Magic Fight</div>
                </div>
                <div className=" text-sm font-semibold">Mar 2021 - Sep 2021(6 mos)</div>
            </div>
            <div className="mt-5">
            <button className="my-2" onClick={ () => window.open('https://apps.apple.com/kr/app/Magig-Fight/1588867897', "_blank") }><strong>https://apps.apple.com/kr/app/Magig-Fight/1588867897</strong> </button>
                <div className="my-2 ">• 매직 파이트는 <strong>1대1 기반 덱빌딩 카드 게임</strong>으로 게임학과 캡스톤 졸업 프로젝트입니다.</div>
                <div className="my-2 ">• 주된 기능으로는 <strong>사용자 매칭, 실시간 데이터 공유, 게임 플레이 정보 분석</strong> 등이 있습니다. </div>
                <div className="flex flex-row">
                    <div className="flex flex-col">
                        <video src={`${process.env.PUBLIC_URL}/assets/magic-fight.mp4`} typeof='video/mp4' controls style={{ width:'75%',height: '200px' }}></video>
                        <img
                            className="w-3/4 h-[200px] my-5"
                            src={`${process.env.PUBLIC_URL}/assets/MagicFight/MagicFight-home.png`}
                        />
                        <img
                            className="w-3/4 h-[200px]"
                            src={`${process.env.PUBLIC_URL}/assets/MagicFight/MagicFight-fight.png`}
                        />

                    </div>

                    <div className="flex flex-col">
                        <img
                            className="w-3/4 h-[200px]"
                            src={`${process.env.PUBLIC_URL}/assets/MagicFight/MagicFight-fire.png`}
                        />
                        <img
                            className="w-3/4 h-[200px] my-5"
                            src={`${process.env.PUBLIC_URL}/assets/MagicFight/MagicFight-item.png`}
                        />
                        <img
                            className="w-3/4 h-[200px]"
                            src={`${process.env.PUBLIC_URL}/assets/MagicFight/MagicFight-victory.png`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}