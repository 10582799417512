import HomeScreen from "./home/HomeScreen";
import AboutScreen from "./about/AboutScreen";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import AppBar from "./app/AppBar";
import { Provider } from "react-redux";
import store from "./redux/store";
import BlogScreen from "./blog/BlogScreen";
import PortfolioScreen from "./Portfolio/PortfolioScreen";

function App() {
  let routes = useRoutes([
    { path: "/", element: <HomeScreen /> },
    { path: "/home", element: <HomeScreen /> },
    { path: "/about", element: <AboutScreen /> },
    { path: "/blog", element: <BlogScreen /> },
    { path: "/portfolio", element: <PortfolioScreen /> },
  ]);
  return routes;
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className="flex-row w-screen h-screen overflow-x-hidden bg-stone-900">
          <AppBar />
          <App />
        </div>
      </Router>
    </Provider>
  );
};

export default AppWrapper;
