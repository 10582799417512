import { useEffect } from "react";
import { BlogState, Post } from "../redux/BlogSlice";
import PostCard from "./components/PostCard";
import CategoryList from "./components/CategoryList";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { allCategoriesDic } from "../utils/BlogAllCategories";

export default function BlogScreen() {
  const blogState = useSelector<RootState, BlogState>(
    (state) => state.blog
  );

  return (
    <div className="flex overflow-visible h-full flex-wrap justify-center bg-stone-900">
      <div className="flex w-full">
        <CategoryList></CategoryList>
        <div className="flex-row">
        <div className={`text-white w-fit rounded-xl p-2 font-serif flex items-center justify-center my-10 text-center text-3xl text-bold`}>{allCategoriesDic[blogState.selectedCategory].name}</div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 ">
          {blogState.posts.map((post: Post) => (
            <PostCard post={post} key={post.id}></PostCard>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
}
