import { blogAPISlice } from './../api/blogAPISlice';
import { appBarSlice } from './AppBarSlice';
import { configureStore } from '@reduxjs/toolkit';
import { blogSlice } from './BlogSlice';
import { portfolioSlice } from './PortfolioSlice';

const store = configureStore({
    reducer: {
        blog: blogSlice.reducer,
        appBar: appBarSlice.reducer,
        portfolio: portfolioSlice.reducer,
        [blogAPISlice.reducerPath]: blogAPISlice.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            blogAPISlice.middleware,
        ),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;