import { useDispatch } from "react-redux"
import { Category, blogSlice } from "../../redux/BlogSlice"
import { allCategories } from "../../utils/BlogAllCategories"

export interface CategoryListDic {
    [key: string]: Category []
}

export default function CategoryList() {
    const parents = allCategories.filter(category => category.parent == "")
    const children: CategoryListDic =  {}
    const categoriesByParent: Category[][] = []
    const actions = blogSlice.actions;
    const dispatch = useDispatch();

    for (let i = 0; i < parents.length; i++) {
        const parent = parents[i]
        children[parent.id] = [parent]
    }

    for (let i = 0; i < allCategories.length; i++) {
        const category = allCategories[i]
        if (category.parent == "") continue
        children[category.parent].push(category)
    }

    for (let i = 0; i < parents.length; i++) {
        const parentId = parents[i].id
        categoriesByParent.push(children[parentId])
    }

    return (
        <div className="flex-row mt-10 md:mx-20 lg:mx-20 sm:mx-10 md:w-1/6 lg:w-1/6" >
            {categoriesByParent.map((categories: Category[]) => (
                categories.map((category: Category) => (
                    <div onClick={() => {
                        dispatch(actions.selectCategory({categoryId:category.id,isParent: category.parent == ""}))
                    }} className={`text-white hover:cursor-pointer ${category.parent == "" ? " text-lg font-bold ml-1 my-2" : "font-normal ml-3 mb-1 " }`} key={category.id}>{category.name}</div>
                ))
            ))}
        </div>
    )
}