import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { none, ProjectInfo } from "../Portfolio/Components/CreativeCoding/ProjectInfo"

export interface PortfolioState {
    selectedCreativeCoding: ProjectInfo
}

const initialState: PortfolioState = {
    selectedCreativeCoding: none
}

export const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState,
    reducers: {
        selectCreativeCoding: (state, action: PayloadAction<{ project: ProjectInfo }>) => {
            state.selectedCreativeCoding = action.payload.project
        },
    }
})