import { Link } from "react-router-dom"
import { Post } from "../../redux/BlogSlice"
import { allCategoriesDic } from "../../utils/BlogAllCategories"

interface PostCardProps {
    post: Post
}

export default function PostCard({ post }: PostCardProps) {
    const postDate = new Date(post.date)
    const parentCategory = allCategoriesDic[allCategoriesDic[post.categoryId].parent] == undefined ? allCategoriesDic[post.categoryId] : allCategoriesDic[allCategoriesDic[post.categoryId].parent]
    const childCategory = allCategoriesDic[post.categoryId]

    return (
        <div className="flex-row items-center w-3/4 my-8 rounded-lg" >
            <div className="font-serif font-medium text-gray-300">{postDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</div>
            <Link to={post.postUrl} className="flex text-lg mt-2 mb-4 font-serif font-bold text-white">
                {post.title.length < 70 ? post.title : post.title.substring(0, 70) + '...'}
            </Link>
            <div className="flex">
                <div className={`flex text-xs font-serif mr-1 ${parentCategory.color} p-1 font-semibold text-white rounded-lg items-center`}>
                    {parentCategory.name}
                </div>
                <div className={`flex items-center ml-1 justify-center rounded-lg p-1 ${childCategory.color}`}>
                    <img
                        className="w-3 h-3 ml-1 rounded-md "
                        src={`${process.env.PUBLIC_URL}/assets/${allCategoriesDic[post.categoryId].imgUrl
                            }`}
                    />
                    <div className="flex text-xs font-serif mr-1 font-semibold text-white items-center">
                        {childCategory.name}
                    </div>
                </div>
            </div>
        </div>
    )
}